<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Информация об организации</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Добавление/изменение инормации об организации</h5>
        </div>
      </div>

      <div class="card-body">
        <a-form-model ref="form" :model="form">
          <a-form-model-item ref="active" label="Тип организации">
            <a-input v-model="form.type" placeholder="Store" />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Url главной страницы сайта">
            <a-input v-model="form.url" placeholder="https://autosill.ua" />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Валюта">
            <a-input v-model="form.priceRange" placeholder="UAH" />
          </a-form-model-item>
          <a-form-model-item
            ref="active"
            label="Название компании, кратко ее деятельность"
          >
            <a-input
              v-model="form.name"
              placeholder="AutoSill.ua — самый крупный авто базар Украины"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Cсылка на логотип">
            <a-input
              v-model="form.logo"
              placeholder="https://api.autosill.ua/storage/settings/logo.png"
            />
          </a-form-model-item>
          <a-form-model-item
            ref="active"
            label="Изображение, которое характеризует деятельность компании"
          >
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              @vdropzone-success="addImage"
              @vdropzone-removed-file="removeImage"
              :options="dropzoneOptions"
            >
            </vue-dropzone>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Соц сети">
            <a-input
              v-model="socialLink"
              placeholder="https://autosill.ua/facebook.com"
            />
            <a-button @click="addSocialLink">Добавить</a-button>
            <div v-for="item in form.sameAs" :key="item.index"><span>{{item}}</span></div>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Какие валюты принимаются">
            <a-input v-model="form.currenciesAccepted" placeholder="UAH" />
          </a-form-model-item>
          <a-form-model-item
            ref="active"
            label="Способы оплаты"
          >
            <a-input v-model="form.paymentAccepted"  placeholder="cash, credit card"/>
          </a-form-model-item>
          <a-form-model-item
            ref="active"
            label="Ссылка на компанию в Google Maps"
          >
            <a-input v-model="form.hasMap" />
          </a-form-model-item>
          <div>Адрес</div>
          <a-form-model-item
            ref="active"
            label="URL с информацией о главном офисе"
          >
            <a-input
              v-model="form.address.id"
              placeholder="https://autosill.ua"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Название компании">
            <a-input v-model="form.address.name" placeholder="Autosill" />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Область">
            <a-input
              v-model="form.address.addressRegion"
              placeholder="Киевская область"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Город">
            <a-input
              v-model="form.address.addressLocality"
              placeholder="Киев"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Индекс">
            <a-input v-model="form.address.postalCode" placeholder="01001" />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Улица, дом">
            <a-input
              v-model="form.address.streetAddress"
              placeholder="ул.Братиславская б 52"
            />
          </a-form-model-item>
          <a-form-model-item
            ref="active"
            label="Телефон"
          >
            <a-input v-model="form.address.telephone"   placeholder="+38093 333 33 33"/>
          </a-form-model-item>
          <a-form-model-item
            ref="active"
            label="Еmail"
          >
            <a-input v-model="form.address.email"  placeholder="autosill.ua@ukr.net"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Код страны">
            <a-input v-model="form.address.addressCountry" placeholder="UA" />
          </a-form-model-item>
          <div>Контакты</div>
          <a-form-model-item ref="active" label="Телефон">
            <a-input
              v-model="form.contactPoint.telephone"
              placeholder="+38093 333 33 33"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Тип контакта">
            <a-input
              v-model="form.contactPoint.contactType"
              placeholder="customer support"
            />
          </a-form-model-item>
          <div>График работы</div>
          <a-form-model-item ref="active" label="Будние дни недели">
            <a-select
              v-model="form.OpeningHoursSpecification[0].dayOfWeek"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              allowClear
              show-search
              multiple="true"
              mode="multiple"
              style="width: 100%"
              placeholder="выберите рабочие дни"
            >
              <a-select-option
                v-for="day in Weekdays"
                :key="day.index"
                :value="day"
              >
                {{ day }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Время с">
            <a-input
              v-model="form.OpeningHoursSpecification[0].opens"
              placeholder="10:00"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Время до">
            <a-input
              v-model="form.OpeningHoursSpecification[0].closes"
              placeholder="21:00"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Выходные дни недели">
            <a-select
              v-model="form.OpeningHoursSpecification[1].dayOfWeek"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              allowClear
              show-search
              multiple="true"
              mode="multiple"
              style="width: 100%"
              placeholder="выберите выходные дни"
            >
              <a-select-option
                v-for="day in Weekend"
                :key="day.index"
                :value="day"
              >
                {{ day }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Время с">
            <a-input
              v-model="form.OpeningHoursSpecification[1].opens"
              placeholder="10:00"
            />
          </a-form-model-item>
          <a-form-model-item ref="active" label="Время до">
            <a-input
              v-model="form.OpeningHoursSpecification[1].closes"
              placeholder="21:00"
            />
          </a-form-model-item>
        </a-form-model>
        <div class="d-flex justify-content-between">
          <a-button class="d-block" type="primary" @click="submit">
            Создать
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'organization-info',

  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      form: {
        type: '',
        url: '',
        priceRange: '',
        name: '',
        logo: '',
        image: [],
        sameAs: [],
        currenciesAccepted: '',
        paymentAccepted: '',
        hasMap: '',
        address: {
          id: '',
          name: '',
          addressRegion: '',
          addressLocality: '',
          postalCode: '',
          streetAddress: '',
          telephone: '',
          email: '',
          addressCountry: '',
        },
        contactPoint: {
          telephone: '',
          contactType: '',
        },
        OpeningHoursSpecification: [
          {
            dayOfWeek: [],
            opens: '',
            closes: '',
          },
          {
            dayOfWeek: [],
            opens: '',
            closes: '',
          },
        ],
      },

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 10,
        acceptedFiles: '.jpeg,.jpg,.png,.pdf',
        addRemoveLinks: true,
      },

      Weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      Weekend: ['Saturday', 'Sunday'],
      socialLink: '',
    }
  },

  async created() {
    try {
      const { data } = await this.$services.get('admin/settings/seo/information')
      this.form = data.data
    } catch (e) { console.log(e) }
  },

  methods: {
    async submit() {
      try {
        await this.$services.post('admin/settings/seo/information', {
          data: this.form,
        })
      } catch (e) {
        console.log(e)
      }
    },

    addImage({ dataURL }) {
      this.form.image.push(dataURL)
    },

    removeImage({ dataURL }) {
      this.form.image = this.form.image.filter((item) => item !== dataURL)
    },

    addSocialLink() {
      this.form.sameAs.push(this.socialLink)
      this.socialLink = ''
    },
  },
}
</script>

<style scoped></style>
